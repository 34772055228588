import React, { useEffect, useState } from 'react'
import {
  FibaDropdown, FibaInput, FibaInputNumber, FibaPhotoInput, FibaTab, FibaTabStrip, FibaTextArea,
  Form, GetCookie, RichTextEditor, SERVER_HOST, useFibaMsgBox, useLoading
} from '../components'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Field } from '@progress/kendo-react-form'
import axios from 'axios'
import { TOKEN_NAME } from '../constants/token.const'
import Tab1 from './Tabs/Tab1'
import Tab4 from './Tabs/Tab4'
import Tab3 from './Tabs/Tab3'

const types = [
  {
    value: '01',
    name: 'Энгийн /Текст/',
  },
  {
    value: '02',
    name: 'Линк холбох',
  },
  {
    value: '03',
    name: 'Файл оруулах',
  },
]

const InformationDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { showLoading } = useLoading()
  const { error } = useFibaMsgBox()
  const [selectedFile, setSelectedFile] = useState()
  const { success } = useFibaMsgBox()
  const [render, setRender] = useState();
  const [showstate, setShowstate] = useState(false);
  const navigate = useNavigate()

  const onChangePicture = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
    } else {
      setSelectedFile(e.target.files[0])
    }
  }

  const onSubmit = async (data) => {
    let process_code = 'ml000012';
    if (data.id) {
      process_code = 'ml000013';
    }
    const formData = new FormData();
    for (const property in data) {
      formData.append(property, data[property]);
    }
    formData.append('files', selectedFile)
    // formData.append('custid', custid)

    showLoading(true)
    axios.post(SERVER_HOST + "/api/v1/back/process", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + GetCookie(TOKEN_NAME),
        'pc': process_code,
      },
      transformRequest: formData => formData,
    }).then((res) => {
      if (res.data.response_code === "RC000000") {
        success(t('success'));
        navigate('/information')
      } else {
        error(res.data.response);
        console.error(res.data);
      }
    }).catch((err) => {
      error('Алдаа гарлаа.');
      console.log(err);
    }).finally(() => {
      showLoading(false);
    })
  }

  useEffect(() => {
    if (!showstate) {
      setShowstate(true)
    }
  }, [showstate])

  return (
    <div className='mx-auto max-w-7xl'>
      <FibaTab tabPosition={'right'}>
        <FibaTabStrip title={'Дэлгэрэнгүй мэдээлэл'}>
          <Form
            initialValues={{ is_public: 0, type: '01', is_print: 0 }}
            detail={'ml000015'}
            create={'ml000012'}
            update={'ml000013'}
            id={id}
            onSubmit={onSubmit}
            setFormEvent={setRender}
            setDetailData={(data) => {
              // getChildMenu(data.module_id)
            }}
          >
            {render && <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">

              <div className='col-span-full'>
                <Field
                  name={'photo1'}
                  component={FibaPhotoInput}
                  label={t('photo1')}
                />
              </div>
              <Field
                name={'name'}
                component={FibaInput}
                label={t('name')}
                required={true}
              />
              <Field
                name={'name2'}
                component={FibaInput}
                label={t('name2')}
                required={true}
              />
              <Field
                name={'order'}
                component={FibaInputNumber}
                label={"Эрэмбэлэлт"}
              />
              <Field
                name={'type'}
                component={FibaDropdown}
                label={t('type')}
                required={true}
                data={types}
                onChange={() => { setShowstate(false) }}
              />
              {(showstate && render.valueGetter('type') === '01') && <div className="col-span-full">
                <Field
                  name={'information'}
                  component={RichTextEditor}
                  label={t('information')}
                  required={true}
                />
              </div>}
              {(showstate && render.valueGetter('type') === '03') && <Field
                name={'file1'}
                type="file"
                component={FibaInput}
                label={t('input_file')}
                onChange={onChangePicture}
                required={id ? false : true}
              />}
              {(showstate && render.valueGetter('type') === '02') && <Field
                name={'information'}
                component={FibaTextArea}
                label={t('information')}
                required={true}
              />}
            </div>}
          </Form>
        </FibaTabStrip>
        <FibaTabStrip title={'Хурлын төлөөлөгчид'}>
          <Tab1 parentid={id} type={1} />
        </FibaTabStrip>
        <FibaTabStrip title={'Бүрэн эрхийн тогтоол'}>
          <Tab3 parentid={id} />
        </FibaTabStrip>
        <FibaTabStrip title={'Ажлын алба'}>
          <Tab1 parentid={id} type={2} />
        </FibaTabStrip>
        <FibaTabStrip title={'Фото агшин'}>
          <Tab4 parentid={id} />
        </FibaTabStrip>
      </FibaTab>

    </div>
  )
}

export default InformationDetail