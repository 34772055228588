import React, { useEffect, useRef, useState } from 'react'
import { FibaInput, FibaInputNumber, FibaPhotoInput, Form, ListScreen, RichTextEditor } from '../../components'
import { GridColumn } from '@progress/kendo-react-grid'
import { Dialog } from "@progress/kendo-react-dialogs";
import { useTranslation } from 'react-i18next';
import { Field } from '@progress/kendo-react-form';

const Tab1 = ({ parentid, type }) => {
    const [show, setShowstate] = useState({ show: false, data: {} })
    const [userSendData, setUserSenddata] = useState({ parentid, type })
    const { t } = useTranslation()
    const gridRef = useRef(null);

    const refreshData = () => {
        if (gridRef.current) {
            gridRef.current.readData()
        }
    }

    const createFunction = () => {
        setShowstate({ show: true, data: {} })
    }
    const detailFuncion = (data) => {
        setShowstate({ show: true, data })
    }

    useEffect(() => {
        setUserSenddata({ parentid, type })
    }, [parentid, type])

    return (
        <div>
            <ListScreen
                read={'ml000016'}
                delete={'ml000020'}
                customFunction={createFunction}
                detailFuncion={detailFuncion}
                createable={false}
                customFunctionText={t('create')}
                userSendData={userSendData}
                ref={gridRef}
            >
                <GridColumn field='position' title={t('position')} filter='text' />
                <GridColumn field='lname' title={t('lname')} filter='text' />
                <GridColumn field='name' title={t('name')} filter='text' />
            </ListScreen>
            {show.show &&
                <Dialog
                    width={'80vw'}
                    height={'auto'}
                    title={t('detail')}
                    onClose={() => { setShowstate({ show: false, data: {} }); }}
                    className="pt-0 px-0"
                >
                    <Form
                        initialValues={show.data}
                        detail={'ml000019'}
                        create={'ml000017'}
                        update={'ml000018'}
                        id={show.data.id}
                        userSendData={userSendData}
                        afterRedirect={false}
                        onFinish={() => {
                            setShowstate({ show: false, data: {} });
                            refreshData()
                        }}
                    >
                        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                            <div className='col-span-full'>
                                <Field
                                    name={'photo'}
                                    component={FibaPhotoInput}
                                    label={t('photo')}
                                />
                            </div>
                            <Field
                                name={'lname'}
                                component={FibaInput}
                                label={t('lname')}
                                required={true}
                            />

                            <Field
                                name={'name'}
                                component={FibaInput}
                                label={t('name')}
                                required={true}
                            />
                            <Field
                                name={'position'}
                                component={FibaInput}
                                label={t('position')}
                                required={true}
                            />

                            <Field
                                name={'order'}
                                component={FibaInputNumber}
                                label={"Эрэмбэлэлт"}
                            />

                            <div className="col-span-full">
                                <Field
                                    name={'information'}
                                    component={RichTextEditor}
                                    label={t('information')}
                                    required={true}
                                />
                            </div>
                        </div>
                    </Form>
                </Dialog>
            }
        </div>
    )
}

export default Tab1