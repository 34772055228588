import React, { useEffect, useRef, useState } from 'react'
import {
    FibaInput, FibaInputNumber, Form, GetCookie,
    ListScreen, SERVER_HOST, useFibaMsgBox, useLoading
} from '../../components'
import { Field } from '@progress/kendo-react-form'
import { useTranslation } from 'react-i18next'
import { GridColumn } from '@progress/kendo-react-grid'
import { Dialog } from "@progress/kendo-react-dialogs";
import axios from 'axios'
import { TOKEN_NAME } from '../../constants/token.const'
import { useNavigate } from 'react-router-dom'
import { PDFViewer } from '@progress/kendo-react-pdf-viewer';

const Tab3 = ({ parentid }) => {
    const [show, setShowstate] = useState({ show: false, data: {} })
    const [userSendData, setUserSenddata] = useState({ parentid })
    const [detaildata, setDetailData] = useState({})
    const { t } = useTranslation()
    const gridRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState()
    const { showLoading } = useLoading()
    const { error, success } = useFibaMsgBox()
    const navigate = useNavigate()

    const refreshData = () => {
        if (gridRef.current) {
            gridRef.current.readData()
        }
    }

    const createFunction = () => {
        setDetailData({})
        setShowstate({ show: true, data: {} })
    }
    const detailFuncion = (data) => {
        setShowstate({ show: true, data })
    }

    const onChangePicture = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
        } else {
            setSelectedFile(e.target.files[0])
        }
    }

    const onSubmit = async (data) => {
        let process_code = 'ml000027';
        if (data.id) {
            process_code = 'ml000028';
        }
        const formData = new FormData();
        for (const property in data) {
            formData.append(property, data[property]);
        }
        formData.append('files', selectedFile)
        formData.append('parentid', parentid)
        // formData.append('custid', custid)

        showLoading(true)
        axios.post(SERVER_HOST + "/api/v1/back/process", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + GetCookie(TOKEN_NAME),
                'pc': process_code,
            },
            transformRequest: formData => formData,
        }).then((res) => {
            if (res.data.response_code === "RC000000") {
                setShowstate({ show: false, data: {} })
                success(t('success'));
            } else {
                error(res.data.response);
                console.error(res.data);
            }
        }).catch((err) => {
            error('Алдаа гарлаа.');
            console.log(err);
        }).finally(() => {
            showLoading(false);
        })
    }

    useEffect(() => {
        setUserSenddata({ parentid })
    }, [parentid])

    return (
        <div>
            <ListScreen
                read={'ml000026'}
                delete={'ml000030'}
                customFunction={createFunction}
                detailFuncion={detailFuncion}
                createable={false}
                customFunctionText={t('create')}
                userSendData={userSendData}
                selectedFieldName={'id'}
                ref={gridRef}
            >
                <GridColumn field='id' title={t('id')} filter='numeric' />
                <GridColumn field='name' title={t('name')} />
                <GridColumn field='order' title={"Эрэмбэлэлт"} filter='numeric' />
            </ListScreen>
            {show.show &&
                <Dialog
                    width={'80vw'}
                    height={'auto'}
                    title={t('detail')}
                    onClose={() => { setShowstate({ show: false, data: {} }); }}
                    className="pt-0 px-0"
                >
                    <Form
                        initialValues={show.data}
                        detail={'ml000029'}
                        create={'ml000027'}
                        update={'ml000028'}
                        onSubmit={onSubmit}
                        id={show.data.id}
                        userSendData={userSendData}
                        afterRedirect={false}
                        onFinish={() => {
                            setShowstate({ show: false, data: {} });
                            refreshData()
                        }}
                        setDetailData={(data) => {
                            setDetailData(data)
                        }}
                    >
                        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
                            {!detaildata.id && <Field
                                name={'file1'}
                                type="file"
                                component={FibaInput}
                                label={t('input_file')}
                                onChange={onChangePicture}
                                accept="image/*,application/pdf"
                            />}

                            <Field
                                name={'name'}
                                component={FibaInput}
                                label={t('name')}
                                required={true}
                            />

                            <Field
                                name={'order'}
                                component={FibaInputNumber}
                                label={"Эрэмбэлэлт"}
                            />
                            <div className="col-span-full">
                                {detaildata.type === 1 && <>
                                    <img alt={detaildata.name} src={`data:image/png;base64, ${detaildata.rfile}`} />
                                </>}
                                {detaildata.type === 2 && <>
                                    <PDFViewer data={detaildata.rfile} style={{
                                        height: 615
                                    }} />
                                </>}
                            </div>
                        </div>
                    </Form>
                </Dialog>
            }
        </div>
    )
}

export default Tab3