import React from 'react'
import { ListScreen } from '../components'
import { GridColumn } from '@progress/kendo-react-grid'
import { useTranslation } from 'react-i18next'

const Information = () => {
  const { t } = useTranslation()
  return (
    <div>
      <ListScreen
        read={'ml000011'}
        delete={'ml000014'}
      >
        <GridColumn field='id' title={t('id')} filter='numeric'></GridColumn>
        <GridColumn field='name' title={t('name')} filter='text'></GridColumn>
        <GridColumn field='order' title={"Эрэмбэлэлт"} filter='numeric'></GridColumn>
      </ListScreen>
    </div>
  )
}

export default Information