import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '../Auth/Login'
import NotFound from '../layouts/error/NotFound'
import PrivateRoute from './PrivateRoute'
import ChangePassword from '../Auth/ChangePassword'
import Profile from '../Auth/Profile'
import Information from '../pages/Information'
import InformationDetail from '../pages/InformationDetail'
import SettingsDetail from '../pages/SettingsDetail'

const loadingComp = <div className="k-loading-mask" style={{ zIndex: 100000 }}>
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
</div>


export default function CoreRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={loadingComp}>
                <Routes>
                    <Route exact path='/' element={<PrivateRoute />}>\
                        <Route path="/changePassword" exact element={<ChangePassword />} />
                        <Route path="/profile" exact element={<Profile />} />
                        <Route path="/information" exact element={<Information />} />
                        <Route path="/information/:id" exact element={<InformationDetail />} />
                        <Route path="/settings" exact element={<SettingsDetail />} />
                    </Route>
                    {/* <PrivateRoute exact path="/login-history" element={LoginHistory} /> */}
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="/forgotpassword" exact element={<ForgotPassword />} />
                    <Route path="/resetPassword/:token" exact element={<ResetPassword />} /> */}
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
