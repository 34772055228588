import React from 'react'
import { useSearchParams } from 'react-router-dom';


export const FibaTab = ({
    selected = 0,
    tabPosition = 'top',
    setQueryTabUrl = false,
    ...props
}) => {
    const tabs = [];
    const [searchParams, setSearchParams] = useSearchParams();
    const [selected1, setSelected] = React.useState(+(searchParams.get("tab") ?? selected));
    let selectedChild = null, tempChildElem = null;
    function renderTabs(element, index) {
        if (!tempChildElem) {
            tempChildElem = element.props.children;
        }
        if (index === selected1 && !(element.props.disabled ?? false)) {
            selectedChild = element.props.children
        }
        tabs.push({
            title: element.props.title ?? "",
            current: index === selected1,
            disabled: element.props.disabled ?? false
        });
    }

    function renderdoTabs(data) {
        data?.forEach((element, index) => {
            if (element) {
                renderTabs(element, index)
            } else {
                tabs.push(false);
            }
        });
    }

    function getListTab() {
        const tmpdata = [];
        props.children?.forEach((element) => {
            if (element) {
                if (Array.isArray(element.props.children)
                    && element.type === React.Fragment) {
                    element.props.children.forEach((elmnt) => {
                        tmpdata.push(elmnt);
                    })
                } else {
                    tmpdata.push(element);
                }
            }
        });
        return tmpdata;
    }

    renderdoTabs(getListTab());

    if (!selectedChild) {
        selectedChild = tempChildElem;
    }

    let position = '', borderPosition = 'b', buttonPosition = '', buttonSpace = '', buttonTextPosition = '';
    switch (tabPosition) {
        case 'top':
            borderPosition = 'b';
            buttonSpace = 'space-x-8';
            buttonTextPosition = 'center'
            break;
        case 'left':
            position = 'flex';
            borderPosition = 'r';
            buttonPosition = 'k-vstack';
            buttonTextPosition = 'right';
            break;
        case 'right':
            position = 'flex flex-row-reverse';
            borderPosition = 'l';
            buttonPosition = 'k-vstack';
            buttonTextPosition = 'left';
            break;
        default:
            break;
    }
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    React.useEffect(() => {
        if (setQueryTabUrl) {
            setSearchParams({ tab: selected1 })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected1])

    React.useEffect(() => {
        if (selected || selected === 0) {
            if (!searchParams.get("tab")) {
                setSelected(+selected);
            }
        }
    }, [searchParams, selected])
    return (
        <div className={position} >
            <div className={`border-${borderPosition} border-slate-200 px-2 shrink`} id="fibatab">
                <div className="sr-only border-l border-r border-b"></div>
                <nav className={`flex flex-wrap ${buttonSpace} ${buttonPosition}`} aria-label="Tabs">
                    {tabs.map((tab, index) => (
                        (tab && <button
                            key={index}
                            className={classNames(
                                tab.disabled ? 'border-transparent cursor-not-allowed' : (
                                    tab.current
                                        ? 'border-primary-500 text-primary-600'
                                        : 'border-transparent hover:text-slate-700 hover:border-slate-300'
                                ),
                                `whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer text-${buttonTextPosition}`
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => { setSelected(index) }}
                            disabled={tab.disabled}
                        >
                            {tab.title}
                        </button>)
                    ))}
                </nav>
            </div>
            <div className='p-2 w-full' style={(tabPosition === 'left') ? {
                maxWidth: `calc(100% - ${document.querySelector('#fibatab')?.offsetWidth}px)`
            } : {}}>
                <div >
                    {selectedChild}
                </div>

            </div>
        </div>
    )
}
