import React from 'react'
import PropTypes from 'prop-types'

export const FibaTabStrip = props => {
  return (
    <div>FibaTabStrip</div>
  )
}

FibaTabStrip.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool
}
