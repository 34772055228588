import React from 'react'
import { FibaInput, FibaPhotoInput, FibaTextArea, Form } from '../components'
import { Field } from '@progress/kendo-react-form'
import { useTranslation } from 'react-i18next'

const SettingsDetail = () => {
    const { t } = useTranslation()

    return (
        <div className="mx-auto max-w-7xl">
            <Form
                initialValues={{ is_public: 0 }}
                detail={'ml000031'}
                read={'ml000032'}
                id={1}
            >
                <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-4">
                    <Field
                        name={'logo1'}
                        component={FibaPhotoInput}
                        label={t('logo')}
                    />

                    <Field
                        name={'logo2'}
                        component={FibaPhotoInput}
                        label={t('logo1')}
                    />

                    <Field
                        name={'logo3'}
                        component={FibaPhotoInput}
                        label={t('logo1')}
                    />
                </div>
                <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 mt-2">
                    <Field
                        name={'name'}
                        component={FibaInput}
                        label={t('name')}
                    />
                    <Field
                        name={'description'}
                        component={FibaTextArea}
                        label={t('description')}
                    />
                </div>

            </Form>

        </div>
    )
}

export default SettingsDetail